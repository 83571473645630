const dev = {
    endpoints: {
        passwordResetSendInvite: "https://2h77gs9og0.execute-api.us-gov-west-1.amazonaws.com/prd/passwordResetSendInvite",
        passwordResetValidateInvite: "https://2h77gs9og0.execute-api.us-gov-west-1.amazonaws.com/prd/passwordResetValidateInvite",
        passwordReset: "https://2h77gs9og0.execute-api.us-gov-west-1.amazonaws.com/prd/passwordReset",
    }
};

const prd = {
    endpoints: {
        passwordResetSendInvite: "https://2h77gs9og0.execute-api.us-gov-west-1.amazonaws.com/prd/passwordResetSendInvite",
        passwordResetValidateInvite: "https://2h77gs9og0.execute-api.us-gov-west-1.amazonaws.com/prd/passwordResetValidateInvite",
        passwordReset: "https://2h77gs9og0.execute-api.us-gov-west-1.amazonaws.com/prd/passwordReset",
    }
};

const configMap = {
    'prd': prd,
    'dev': dev,
};

const config = configMap[process.env.REACT_APP_STAGE];

export const endpoints = config.endpoints;
